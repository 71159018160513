@import "../../assets/styles/utils";



.index-page{
    &.white-header{
        .header{
            .header-cont{
                background-color: #fff;
                background-image: none;
            }
            .left-logo{
                a{
                    img{
                        &.red-logo{
                            opacity: 1;
                        }
                        &.white-logo{
                            opacity: 0;
                        }
                    }
                }
            }
            .right-nav{
                ul{
                    li{
                        .nav-title{
                            &::after{
                                background: $color-main;
                            }
                            a{
                                color: $color-text;
                            }
                        }
                    }
                }
            }
            .open-menu{
                .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
                    background-color: $color-main;
                }
            }
        }
    }
    header{
        .header-placeholder{
            height: 0;
        }
        .header{
            .header-cont{
                background-color: transparent;
                background-image: linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,0));
            }
            .left-logo{
                a{
                    img{
                        &.red-logo{
                            opacity: 0;
                        }
                        &.white-logo{
                            opacity: 1;
                        }
                    }
                }
            }
            .right-nav{
                ul{
                    li{
                        .nav-title{
                            &::after{
                                background: #fff;
                            }
                            a{
                                color: #fff;
                            }
                        }
                    }
                }
            }
            .open-menu{
                .hamburger>div, .hamburger>div:after, .hamburger>div:before, .hamburger>view, .hamburger>view:after, .hamburger>view:before{
                    background-color: #fff;
                }
            }
        }
    }
}

.index-banner{
    overflow: hidden;
    box-sizing: border-box;
    @include res(height,100vh);
    .banner-cont{
        height: 100%;
        position: relative;
        .swiper-container{
            height: 100%;
            overflow: hidden;
            @include res(width,100%);
            .swiper-slide{
                overflow: hidden;
                &.swiper-slide-active,&.swiper-slide-duplicate-active{
                    .slide-inner{
                        b{
                            transform: scale(1);
                        }
                    }
                }
                .slide-inner{
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    left: 0;
                    top: 0;
                    // @include res(width,76.82vw);
                    b{
                        display: block;
                        width: 100%;
                        height: 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transform: scale(1.2);
                        transition: all .8s;
                        transition-delay: .2s;
                    }
                    &::after{
                        content: '';
                        display: block;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0,0,0,.2);
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
        .swiper-pagination{
            height: 100%;
            top:0;
            bottom: auto;
            left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            @include res(width,40px,(sm:28px,xs:auto));
            @include res(right,50px,10 / 50);
            .swiper-pagination-bullet{
                opacity: 1;
                background: transparent;
                position: relative;
                box-sizing: border-box;
                margin-left: 1px;
                @include res(width,40px,(sm:28px,xs:auto));
                @include res(height,40px,(sm:28px,xs:auto));
                @include res(border,1px solid rgba(255,255,255,.3),(xs:none));
                &:not(:last-child){
                    @include res(margin-bottom,20px, 15 / 20);
                }
                &::after{
                    content: '';
                    display: block;
                    background: #fff;
                    border-radius: 50%;
                    top: calc(50% - 4px);
                    left: calc(50% - 4px);
                    transition: all .3s;
                    transform-origin: center;
                    @include res(opacity,0,(xs:.6));
                    @include res(width,8px,(xs:4px));
                    @include res(height,8px,(xs:4px));
                    @include res(height,8px,(xs:4px));
                    @include res(position,absolute,(xs:static));
                }
                &.swiper-pagination-bullet-active{
                    &::after{
                        opacity: 1;
                        @include res(transform,null,(xs:scale(1.5)));
                    }
                    svg{
                        circle{
                            // animation:6s circle linear;
                            @include res(animation,6s circle linear,(sm:6s circle2 linear));
                        }
                    }
                }
                svg{
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    z-index: 1;
                    transform-origin: center;
                    transform-box: fill-box;
                    transform: rotate(-90deg);
                    @include res(width,40px,(sm:28px));
                    @include res(height,40px,(sm:28px));
                    @include res(display,null,(xs:none));
                    circle {
                        stroke-width: 1;
                        fill: none;
                        stroke: #fff;
                        // stroke-dasharray: 120px;
                        // stroke-dashoffset: 120px;
                        @include res(cx,20px,(sm:14px));
                        @include res(cy,20px,(sm:14px));
                        @include res(r,19px,(sm:13px));
                        @include res(stroke-dasharray,120px,(sm:82px));
                        @include res(stroke-dashoffset,120px,(sm:82px));
                    }
                }
            }
        }
        .index-banner-text{
            height: 0;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 2;
            color: #fff;
            box-sizing: border-box;
            .text-item{
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                @include res(text-align,left,(xs:center));
                @include res(width,null,(xs:100%));
                @include res(padding-left,205px, 20 / 205);
                @include res(padding-right,50px, 30 / 50);
                .title1,.title2{
                    overflow: hidden;
                    span{
                        opacity: 0;
                    }
                }
                .title1{
                    font-weight: bold;
                    @include res(font-size,80px, 26 / 80);
                    @include res(margin-bottom,25px, 10 / 25);
                    span{
                        display: block;
                        transform: translateY(100%);
                        transition-delay: .5s;
                        transition: all .7s;
                    }
                }
                .title2{
                    @include res(font-size,30px, 16 / 30);
                    span{
                        display: block;
                        transform: translateY(100%);
                        transition-delay: .9s;
                        transition: all .8s;
                    }
                }
                &.active{
                    .title1,.title2{
                        span{
                            opacity: 1;
                            transform: translateY(0px);
                        }
                    }
                }
            }
        }
        .num-pagination{
            position: absolute;
            color: #fff;
            z-index: 2;
            @include res(bottom,180px, 30 / 180);
            @include res(right,65px,10 / 65);
        }
        .scroll-desc{
            position: absolute;
            color: #fff;
            z-index: 2;
            align-items: center;
            justify-content: flex-start;
            @include res(display,flex);
            @include res(bottom,120px, 20 / 120);
            @include res(left,205px, 20 / 205);
            .line{
                background-color: #fff;
                opacity: .8;
                animation: scrollrain 1.5s infinite;
                @include res(width,2px, 1 / 2);
                @include res(height,80px, 30 / 80);
                @include res(margin-right,35px, 15 / 35);
            }
            .t{
                font-weight: bold;
                @include res(font-size,14px, 12 / 14);
            }
        }
    }
}

.index-solution-pc{
    @include res(height,100vh,(sm:auto,xs:auto));
    .solution-swiper{
        height: 100%;
        .swiper-slide{
            &:nth-child(1){
                background-color: #000;
            }
            &:nth-child(2){
                background-color: red;
            }
            &:nth-child(3){
                background-color: green;
            }
        }
    }
}

.index-solution{
    position: relative;
    .progress-bar{
        position: absolute;
        height: 100vh;
        top: 0;
        overflow: hidden;
        @include res(width,4px, 2 / 4);
        @include res(right,45%,(sm:0));
        @include res(display,block,(sm:none));
        .line{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0;
            background-color: #cc20df;
        }
        &.fixed{
            position: fixed;
        }
        &.abs{
            position: absolute;
            bottom: 0;
            top: auto;
        }
    }
    .solution-item{
        align-items: stretch;
        justify-content: space-between;
        @include res(height,100vh,(md:500px,sm:auto,xs:auto));
        @include res(display,flex,(sm:block));
        // &:not(:last-child){
            @include res(padding-bottom,null,(xs:20px));
        // }
        &:nth-child(odd){
            background-color: $color-main-bg;
        }
        .mob-bg{
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            @include res(height,300px,(xs:200px));
            @include res(display,none,(sm:block));
            .t{
                width: 100%;
                box-sizing: border-box;
                position: absolute;
                left: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                background-color: rgba(9,89,163,.8);
                @include res(color,#fff);
                @include res(font-size,28px, 16 / 28);
                @include res(padding-bottom,20px, 15 / 20);
                @include res(padding-top,20px, 15 / 20);
                @include res(padding-left,20px, 15 / 20);
                @include res(padding-right,20px, 15 / 20);
                &::before{
                    content: '';
                    display: block;
                    border-radius: 50%;
                    background-color: #fff;
                    @include res(margin-right,10px);
                    @include res(width,10px, 6 / 10);
                    @include res(height,10px, 6 / 10);
                }
            }
        }
        .item-left{
            flex-shrink: 0;
            box-sizing: border-box;
            position: relative;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            @include res(display,flex);
            @include res(width,55%,(sm:100%));
            @include res(height,100%);
            @include res(padding-left,50px, 10 / 50);
            @include res(padding-right,50px, 10 / 50);
            @include res(padding-top,120px, 20 / 120);
            @include res(padding-bottom,120px, 20 / 120);
            .text-top{
                @include res(display,block,(sm:none));
                @include res(margin-bottom,55px, 45 / 55);
                h1{
                    align-items: center;
                    @include res(display,flex,(sm:none));
                    @include res(color,$color-main-light);
                    @include res(font-size,28px, 16 / 28);
                    @include res(margin-bottom,35px, 15 / 35);
                    &::before{
                        content: '';
                        display: block;
                        border-radius: 50%;
                        @include res(background-color,$color-main);
                        @include res(margin-right,10px);
                        @include res(width,10px, 6 / 10);
                        @include res(height,10px, 6 / 10);
                    }
                }
                h2{
                    font-weight: bold;
                    text-transform: uppercase;
                    line-height: .9;
                    @include res(color,$color-main);
                    @include res(font-size,80px, 22 / 80);
                }
            }
            .text-bottom{
                .item-link{
                    display: flex;
                    align-items: stretch;
                    justify-content: space-between;
                    @include res(margin-bottom,50px, 15 / 50);
                    a{
                        flex-grow: 1;
                        font-weight: bold;
                        display: flex;
                        align-items: center;
                        position: relative;
                        transition: all .3s $anime-bezier;
                        @include res(width,50%);
                        @include res(font-size,24px, 16 / 24);
                        @include res(color,$color-main);
                        @include res(padding-bottom,30px, 10 / 30);
                        &:hover{
                            color: $color-main-light;
                            &::after{
                                transform: scaleX(1);
                                // transform-origin: left;
                            }
                        }
                        &::before{
                            content: '';
                            width: 100%;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            @include res(height,3px, 2 / 3);
                            @include res(background-color,$color-main-light);
                        }
                        &::after{
                            content: '';
                            width: 100%;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            transform: scaleX(0);
                            transform-origin: left;
                            transition: transform .3s $anime-bezier;
                            @include res(height,3px, 2 / 3);
                            @include res(background-color,$color-main);
                        }
                        span{
                            font-weight: normal;
                            @include res(color,#7f8faf);
                            @include res(font-size,16px, 12 / 16);
                            @include res(margin-right,15px, 10 / 15);
                        }
                    }
                }
                .item-desc{
                    @include res(line-height,1.8, 1.6 / 1.8);
                    @include res(color,$color-text);
                    @include res(font-size,18px, 14 / 18);
                    @include res(margin-bottom,40px, 20 / 40);
                }
            }
        }
        .item-right{
            flex-shrink: 0;
            background-attachment: fixed;
            background-position: right center;
            background-repeat: no-repeat;
            background-size: auto 120%;
            @include res(width,45%,(sm:0));
        }
    }
}

.index-application{
    background-color: $color-main-bg;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    @include res(height,100vh,(md:550px,sm:450px,xs:auto));
    @include res(display,flex);
    @include res(padding-top,50px, 20 / 50);
    @include res(padding-bottom,50px, 20 / 50);
    .application-title{
        width: 100%;
        align-items: flex-end;
        justify-content: space-between;
        border-bottom: solid $color-main;
        @include res(border-width,3px, 2 / 3);
        @include res(display,flex);
        @include res(align-items,flex-end,(sm:center));
        @include res(padding-bottom,50px, 15 / 50);
        @include res(margin-bottom,60px, 20 / 60);
        .left-title{
            .t1{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                color: $color-main;
                @include res(font-size,28px, 16 / 28);
                &::before{
                    content: '';
                    display: block;
                    border-radius: 50%;
                    background-color: $color-main;
                    @include res(background-color,$color-main);
                    @include res(margin-right,10px);
                    @include res(width,10px, 6 / 10);
                    @include res(height,10px, 6 / 10);
                }
            }
            .t2{
                color: $color-main;
                font-weight: bold;
                text-transform: uppercase;
                line-height: .8;
                @include res(margin-top,40px, 15 / 40);
                @include res(display,block,(sm:none));
                @include res(font-size,100px, 22 / 100);
            }
        }
        .right-more{
            // @include res(margin-top,0,(xs:15px));
            .common-more{
                a{
                    @include res(width,null,(xs:auto));
                    @include res(padding-top,null,(xs:3px));
                    @include res(padding-bottom,null,(xs:3px));
                    @include res(padding-left,null,(xs:14px));
                    @include res(padding-right,null,(xs:14px));
                    span{
                        @include res(display,null,(xs:none));
                    }
                }
            }
        }
    }
    .cont{
        ul{
            align-items: stretch;
            justify-content: space-between;
            @include res(display,flex,(xs:block));
            @include res(margin-left,-25px,(md:-15px,sm:-10px,xs:0));
            @include res(margin-right,-25px,(md:-15px,sm:-10px,xs:0));
            li{
                flex-shrink: 0;
                box-sizing: border-box;
                @include res(width,33.33%,(xs:100%));
                @include res(padding-left,25px,(md:15px,sm:10px,xs:0));
                @include res(padding-right,25px,(md:15px,sm:10px,xs:0));
                &:not(:last-child){
                    @include res(margin-bottom,0,(xs:20px));
                    @include res(border-right,1px solid #c2cada,(xs:none));
                }
                a{
                    display: flex;
                    height: 100%;
                    align-items: flex-start;
                    justify-content: space-between;
                    flex-direction: column;
                }
                .item-text{
                    @include res(margin-bottom,30px, 15 / 30);
                    .item-title{
                        font-weight: bold;
                        color: $color-main;
                        @include res(font-size,24px, 16 / 24);
                        @include res(margin-bottom,15px, 8 / 15);
                    }
                    .item-desc{
                        @include res(font-size,18px, 14 / 18);
                    }
                }
                .item-img{
                    position: relative;
                    overflow: hidden;
                    &:hover{
                        img{
                            transform: scale(1.1);
                            filter: contrast(130%);
                        }
                        .img-t{
                            @include res(transform,translateX(-100%),(md:translateX(0)));
                        }
                    }
                    img{
                        display: block;
                        width: 100%;
                        height: auto;
                        transition: all .5s;
                    }
                    .img-t{
                        position: absolute;
                        background-color: rgba(0,186,231,.89);
                        color: #fff;
                        box-sizing: border-box;
                        align-items: center;
                        justify-content: flex-end;
                        transition: all .3s $anime-bezier;
                        @include res(bottom,0);
                        @include res(left,100%,(md:auto));
                        @include res(right,auto,(md:0));
                        @include res(display,flex);
                        @include res(padding-bottom,30px, 15 / 30);
                        @include res(padding-top,30px, 15 / 30);
                        @include res(padding-left,10px, 10 / 10);
                        @include res(padding-right,10px, 10 / 10);
                        @include res(width,19%);
                        @include res(height,100%);
                        @include res(writing-mode,vertical-lr);
                        span{
                            @include res(margin-bottom,10px);
                        }
                    }
                }
            }
        }
    }
}

.index-news{
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    @include res(height,100vh,(md:600px,sm:500px,xs:auto));
    @include res(display,flex);
    @include res(padding-top,50px, 20 / 50);
    @include res(padding-bottom,50px, 20 / 50);
    .news-title{
        width: 100%;
        justify-content: space-between;
        border-bottom: solid $color-main-light;
        position: relative;
        @include res(border-width,3px, 2 / 3);
        @include res(display,flex);
        @include res(align-items,center);
        @include res(padding-bottom,50px, 15 / 50);
        @include res(margin-bottom,20px, 0 / 20);
        .left-title{
            .t1{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                color: $color-main-light;
                @include res(font-size,28px, 16 / 28);

                &::before{
                    content: '';
                    display: block;
                    border-radius: 50%;
                    background-color: $color-main;
                    @include res(background-color,$color-main);
                    @include res(margin-right,10px);
                    @include res(width,10px, 6 / 10);
                    @include res(height,10px, 6 / 10);
                }
            }
            .t2{
                color: $color-main;
                font-weight: bold;
                text-transform: uppercase;
                line-height: .8;
                @include res(display,block,(sm:none));
                @include res(margin-top,40px, 15 / 40);
                @include res(font-size,80px, 22 / 80);
            }
        }
        .right-more{
            // @include res(margin-top,0,(xs:15px));
            .common-more{
                a{
                    @include res(width,null,(xs:auto));
                    @include res(padding-top,null,(xs:3px));
                    @include res(padding-bottom,null,(xs:3px));
                    @include res(padding-left,null,(xs:14px));
                    @include res(padding-right,null,(xs:14px));
                    span{
                        @include res(display,null,(xs:none));
                    }
                }
            }
        }
        .swiper-controller{
            position: absolute;
            display: flex;
            align-items: center;
            @include res(bottom,0,(sm:30px,xs:17px));
            @include res(right,0,(sm:200px,xs:70px));
            @include res(height,70px, 30 / 70);
            .swiper-button-prev,.swiper-button-next{
                position: static;
                margin: 0;
                &::after{
                    color: $color-main;
                    font-family: 'iconfont';
                    @include res(font-size,40px, 30 / 40);
                }
            }
            .swiper-button-prev{
                @include res(padding-right,15px, 0 / 15);
                &::after{
                    content: '\eb05';
                }
            }
            .swiper-button-next{
                @include res(padding-left,15px, 0 / 15);
                &::after{
                    content: '\eb03';
                }
            }
        }
    }
    .news-swiper{
        width: 100%;
        .swiper{
            @include res(padding-top,40px, 20 / 40);
            @include res(padding-left, 50px, 10 / 50);
            @include res(padding-right, 50px, 10 / 50);
            .swiper-slide{
                &:first-child{
                    a{
                        border-left: 1px solid #d0d6e2;
                    }
                }
                a{
                    display: block;
                    position: relative;
                    border-top: 1px solid #d0d6e2;
                    border-bottom: 1px solid #d0d6e2;
                    border-right: 1px solid #d0d6e2;
                    transition: all .3s;
                    overflow: hidden;
                    transform-origin: bottom;
                    @include res(padding-top,90px, 30 / 90);
                    @include res(padding-bottom,90px, 30 / 90);
                    @include res(padding-left,45px, 15 / 45);
                    @include res(padding-right,45px, 15 / 45);
                    &:hover{
                        transform: scaleY(1.04);
                        z-index: 1;
                        // transform: translateY(-10px);
                        box-shadow: 0 0 10px rgba(0,0,0,.3);
                        background: $color-main;
                        .item-img{
                            opacity: .5;
                            transform: scale(1.05);
                        }
                        .item-title,.item-desc,.item-date{
                            color: #fff;
                        }
                        .item-desc{
                            opacity: 1;
                        }
                    }
                    .item-img{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 0;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        transition: all .3s;
                        opacity: 0;
                    }
                    .item-title,.item-desc,.item-date{
                        position: relative;
                        z-index: 1;
                        transition: all .25s;
                    }
                    .item-title{
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        @include res(height,76px,50 /76);
                        @include res(line-height,1.6);
                        @include res(font-size,24px, 16 / 24);
                        @include res(margin-bottom,60px, 20 / 60);
                    }
                    .item-desc{
                        @include res(color,#fff,(md:$color-text));
                        @include res(opacity,0,(md:1));
                        @include res(height,90px,63 / 90);
                        @include res(line-height,1.875, 1.6 / 1.875);
                        @include res(margin-bottom,120px, 30 / 120);
                        .t-cont{
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                            overflow: hidden;
                        }
                    }
                    .item-date{
                        color: #7082a6;
                    }
                }
            }
        }
    }
}

//圆形进度条
@keyframes circle {
  0% {
    stroke-dashoffset: 120px;
  }
  50% {
    stroke-dashoffset: 60px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}
@keyframes circle2 {
  0% {
    stroke-dashoffset: 82px;
  }
  50% {
    stroke-dashoffset: 41px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}
// 滚动提示
@keyframes scrollrain{
    0%{
        transform: scaleY(0);
        transform-origin: top;
    }
    50%{
        transform: scaleY(1);
        transform-origin: top;
    }
    60%{
        transform-origin: bottom;
    }
    100%{
        transform: scaleY(0);
        transform-origin: bottom;
    }
}
