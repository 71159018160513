.index-page.white-header .header .header-cont {
  background-color: #fff;
  background-image: none;
}

.index-page.white-header .header .left-logo a img.red-logo {
  opacity: 1;
}

.index-page.white-header .header .left-logo a img.white-logo {
  opacity: 0;
}

.index-page.white-header .header .right-nav ul li .nav-title::after {
  background: #317ec6;
}

.index-page.white-header .header .right-nav ul li .nav-title a {
  color: #585858;
}

.index-page.white-header .header .open-menu .hamburger > div, .index-page.white-header .header .open-menu .hamburger > div:after, .index-page.white-header .header .open-menu .hamburger > div:before, .index-page.white-header .header .open-menu .hamburger > view, .index-page.white-header .header .open-menu .hamburger > view:after, .index-page.white-header .header .open-menu .hamburger > view:before {
  background-color: #317ec6;
}

.index-page header .header-placeholder {
  height: 0;
}

.index-page header .header .header-cont {
  background-color: transparent;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
}

.index-page header .header .left-logo a img.red-logo {
  opacity: 0;
}

.index-page header .header .left-logo a img.white-logo {
  opacity: 1;
}

.index-page header .header .right-nav ul li .nav-title::after {
  background: #fff;
}

.index-page header .header .right-nav ul li .nav-title a {
  color: #fff;
}

.index-page header .header .open-menu .hamburger > div, .index-page header .header .open-menu .hamburger > div:after, .index-page header .header .open-menu .hamburger > div:before, .index-page header .header .open-menu .hamburger > view, .index-page header .header .open-menu .hamburger > view:after, .index-page header .header .open-menu .hamburger > view:before {
  background-color: #fff;
}

.index-banner {
  overflow: hidden;
  box-sizing: border-box;
  height: 100vh;
}

.index-banner .banner-cont {
  height: 100%;
  position: relative;
}

.index-banner .banner-cont .swiper-container {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.index-banner .banner-cont .swiper-container .swiper-slide {
  overflow: hidden;
}

.index-banner .banner-cont .swiper-container .swiper-slide.swiper-slide-active .slide-inner b, .index-banner .banner-cont .swiper-container .swiper-slide.swiper-slide-duplicate-active .slide-inner b {
  transform: scale(1);
}

.index-banner .banner-cont .swiper-container .swiper-slide .slide-inner {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.index-banner .banner-cont .swiper-container .swiper-slide .slide-inner b {
  display: block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.2);
  transition: all .8s;
  transition-delay: .2s;
}

.index-banner .banner-cont .swiper-container .swiper-slide .slide-inner::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
}

.index-banner .banner-cont .swiper-pagination {
  height: 100%;
  top: 0;
  bottom: auto;
  left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 40px;
  right: 50px;
}

@media (max-width: 991px) {
  .index-banner .banner-cont .swiper-pagination {
    width: 28px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination {
    width: auto;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .swiper-pagination {
    right: 42px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .swiper-pagination {
    right: 34px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .swiper-pagination {
    right: 26px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .swiper-pagination {
    right: 18px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination {
    right: 10px;
  }
}

.index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet {
  opacity: 1;
  background: transparent;
  position: relative;
  box-sizing: border-box;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

@media (max-width: 991px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet {
    width: 28px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet {
    width: auto;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet {
    height: 28px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet {
    height: auto;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet {
    border: none;
  }
}

.index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
  margin-bottom: 20px;
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 19px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 18px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 17px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 15px;
  }
}

.index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet::after {
  content: '';
  display: block;
  background: #fff;
  border-radius: 50%;
  top: calc(50% - 4px);
  left: calc(50% - 4px);
  transition: all .3s;
  transform-origin: center;
  opacity: 0;
  width: 8px;
  height: 8px;
  height: 8px;
  position: absolute;
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet::after {
    opacity: 0.6;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet::after {
    width: 4px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet::after {
    height: 4px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet::after {
    height: 4px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet::after {
    position: static;
  }
}

.index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  opacity: 1;
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
    transform: scale(1.5);
  }
}

.index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active svg circle {
  animation: 6s circle linear;
}

@media (max-width: 991px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active svg circle {
    animation: 6s circle2 linear;
  }
}

.index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet svg {
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: 1;
  transform-origin: center;
  transform-box: fill-box;
  transform: rotate(-90deg);
  width: 40px;
  height: 40px;
}

@media (max-width: 991px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet svg {
    width: 28px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet svg {
    height: 28px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet svg {
    display: none;
  }
}

.index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet svg circle {
  stroke-width: 1;
  fill: none;
  stroke: #fff;
  cx: 20px;
  cy: 20px;
  r: 19px;
  stroke-dasharray: 120px;
  stroke-dashoffset: 120px;
}

@media (max-width: 991px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet svg circle {
    cx: 14px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet svg circle {
    cy: 14px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet svg circle {
    r: 13px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet svg circle {
    stroke-dasharray: 82px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .swiper-pagination .swiper-pagination-bullet svg circle {
    stroke-dashoffset: 82px;
  }
}

.index-banner .banner-cont .index-banner-text {
  height: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
  color: #fff;
  box-sizing: border-box;
}

.index-banner .banner-cont .index-banner-text .text-item {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-align: left;
  padding-left: 205px;
  padding-right: 50px;
}

@media (max-width: 767px) {
  .index-banner .banner-cont .index-banner-text .text-item {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .index-banner-text .text-item {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .index-banner-text .text-item {
    padding-left: 168px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .index-banner-text .text-item {
    padding-left: 131px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .index-banner-text .text-item {
    padding-left: 94px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .index-banner-text .text-item {
    padding-left: 57px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .index-banner-text .text-item {
    padding-left: 20px;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .index-banner-text .text-item {
    padding-right: 46px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .index-banner-text .text-item {
    padding-right: 42px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .index-banner-text .text-item {
    padding-right: 38px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .index-banner-text .text-item {
    padding-right: 34px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .index-banner-text .text-item {
    padding-right: 30px;
  }
}

.index-banner .banner-cont .index-banner-text .text-item .title1, .index-banner .banner-cont .index-banner-text .text-item .title2 {
  overflow: hidden;
}

.index-banner .banner-cont .index-banner-text .text-item .title1 span, .index-banner .banner-cont .index-banner-text .text-item .title2 span {
  opacity: 0;
}

.index-banner .banner-cont .index-banner-text .text-item .title1 {
  font-weight: bold;
  font-size: 80px;
  margin-bottom: 25px;
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    font-size: 69.2px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    font-size: 58.4px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    font-size: 47.6px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    font-size: 36.8px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    font-size: 26px;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    margin-bottom: 22px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    margin-bottom: 19px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    margin-bottom: 16px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    margin-bottom: 13px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .index-banner-text .text-item .title1 {
    margin-bottom: 10px;
  }
}

.index-banner .banner-cont .index-banner-text .text-item .title1 span {
  display: block;
  transform: translateY(100%);
  transition-delay: .5s;
  transition: all .7s;
}

.index-banner .banner-cont .index-banner-text .text-item .title2 {
  font-size: 30px;
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    font-size: 27.2px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    font-size: 24.4px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    font-size: 21.6px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    font-size: 18.8px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .index-banner-text .text-item .title2 {
    font-size: 16px;
  }
}

.index-banner .banner-cont .index-banner-text .text-item .title2 span {
  display: block;
  transform: translateY(100%);
  transition-delay: .9s;
  transition: all .8s;
}

.index-banner .banner-cont .index-banner-text .text-item.active .title1 span, .index-banner .banner-cont .index-banner-text .text-item.active .title2 span {
  opacity: 1;
  transform: translateY(0px);
}

.index-banner .banner-cont .num-pagination {
  position: absolute;
  color: #fff;
  z-index: 2;
  bottom: 180px;
  right: 65px;
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .num-pagination {
    bottom: 150px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .num-pagination {
    bottom: 120px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .num-pagination {
    bottom: 90px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .num-pagination {
    bottom: 60px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .num-pagination {
    bottom: 30px;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .num-pagination {
    right: 54px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .num-pagination {
    right: 43px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .num-pagination {
    right: 32px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .num-pagination {
    right: 21px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .num-pagination {
    right: 10px;
  }
}

.index-banner .banner-cont .scroll-desc {
  position: absolute;
  color: #fff;
  z-index: 2;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  bottom: 120px;
  left: 205px;
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .scroll-desc {
    bottom: 100px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .scroll-desc {
    bottom: 80px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .scroll-desc {
    bottom: 60px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .scroll-desc {
    bottom: 40px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .scroll-desc {
    bottom: 20px;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .scroll-desc {
    left: 168px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .scroll-desc {
    left: 131px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .scroll-desc {
    left: 94px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .scroll-desc {
    left: 57px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .scroll-desc {
    left: 20px;
  }
}

.index-banner .banner-cont .scroll-desc .line {
  background-color: #fff;
  opacity: .8;
  animation: scrollrain 1.5s infinite;
  width: 2px;
  height: 80px;
  margin-right: 35px;
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .scroll-desc .line {
    width: 1.8px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .scroll-desc .line {
    width: 1.6px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .scroll-desc .line {
    width: 1.4px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .scroll-desc .line {
    width: 1.2px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .scroll-desc .line {
    width: 1px;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .scroll-desc .line {
    height: 70px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .scroll-desc .line {
    height: 60px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .scroll-desc .line {
    height: 50px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .scroll-desc .line {
    height: 40px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .scroll-desc .line {
    height: 30px;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .scroll-desc .line {
    margin-right: 31px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .scroll-desc .line {
    margin-right: 27px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .scroll-desc .line {
    margin-right: 23px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .scroll-desc .line {
    margin-right: 19px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .scroll-desc .line {
    margin-right: 15px;
  }
}

.index-banner .banner-cont .scroll-desc .t {
  font-weight: bold;
  font-size: 14px;
}

@media (max-width: 1600px) {
  .index-banner .banner-cont .scroll-desc .t {
    font-size: 13.6px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-cont .scroll-desc .t {
    font-size: 13.2px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-cont .scroll-desc .t {
    font-size: 12.8px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-cont .scroll-desc .t {
    font-size: 12.4px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-cont .scroll-desc .t {
    font-size: 12px;
  }
}

.index-solution-pc {
  height: 100vh;
}

@media (max-width: 991px) {
  .index-solution-pc {
    height: auto;
  }
}

@media (max-width: 767px) {
  .index-solution-pc {
    height: auto;
  }
}

.index-solution-pc .solution-swiper {
  height: 100%;
}

.index-solution-pc .solution-swiper .swiper-slide:nth-child(1) {
  background-color: #000;
}

.index-solution-pc .solution-swiper .swiper-slide:nth-child(2) {
  background-color: red;
}

.index-solution-pc .solution-swiper .swiper-slide:nth-child(3) {
  background-color: green;
}

.index-solution {
  position: relative;
}

.index-solution .progress-bar {
  position: absolute;
  height: 100vh;
  top: 0;
  overflow: hidden;
  width: 4px;
  right: 45%;
  display: block;
}

@media (max-width: 1600px) {
  .index-solution .progress-bar {
    width: 3.6px;
  }
}

@media (max-width: 1366px) {
  .index-solution .progress-bar {
    width: 3.2px;
  }
}

@media (max-width: 1024px) {
  .index-solution .progress-bar {
    width: 2.8px;
  }
}

@media (max-width: 991px) {
  .index-solution .progress-bar {
    width: 2.4px;
  }
}

@media (max-width: 767px) {
  .index-solution .progress-bar {
    width: 2px;
  }
}

@media (max-width: 991px) {
  .index-solution .progress-bar {
    right: 0;
  }
}

@media (max-width: 991px) {
  .index-solution .progress-bar {
    display: none;
  }
}

.index-solution .progress-bar .line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #cc20df;
}

.index-solution .progress-bar.fixed {
  position: fixed;
}

.index-solution .progress-bar.abs {
  position: absolute;
  bottom: 0;
  top: auto;
}

.index-solution .solution-item {
  align-items: stretch;
  justify-content: space-between;
  height: 100vh;
  display: flex;
}

@media (max-width: 1024px) {
  .index-solution .solution-item {
    height: 500px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item {
    height: auto;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item {
    height: auto;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item {
    display: block;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item {
    padding-bottom: 20px;
  }
}

.index-solution .solution-item:nth-child(odd) {
  background-color: #f2f4f8;
}

.index-solution .solution-item .mob-bg {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 300px;
  display: none;
}

@media (max-width: 767px) {
  .index-solution .solution-item .mob-bg {
    height: 200px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .mob-bg {
    display: block;
  }
}

.index-solution .solution-item .mob-bg .t {
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: rgba(9, 89, 163, 0.8);
  color: #fff;
  font-size: 28px;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 1600px) {
  .index-solution .solution-item .mob-bg .t {
    font-size: 25.6px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .mob-bg .t {
    font-size: 23.2px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .mob-bg .t {
    font-size: 20.8px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .mob-bg .t {
    font-size: 18.4px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .mob-bg .t {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-solution .solution-item .mob-bg .t {
    padding-bottom: 19px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .mob-bg .t {
    padding-bottom: 18px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .mob-bg .t {
    padding-bottom: 17px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .mob-bg .t {
    padding-bottom: 16px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .mob-bg .t {
    padding-bottom: 15px;
  }
}

@media (max-width: 1600px) {
  .index-solution .solution-item .mob-bg .t {
    padding-top: 19px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .mob-bg .t {
    padding-top: 18px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .mob-bg .t {
    padding-top: 17px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .mob-bg .t {
    padding-top: 16px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .mob-bg .t {
    padding-top: 15px;
  }
}

@media (max-width: 1600px) {
  .index-solution .solution-item .mob-bg .t {
    padding-left: 19px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .mob-bg .t {
    padding-left: 18px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .mob-bg .t {
    padding-left: 17px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .mob-bg .t {
    padding-left: 16px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .mob-bg .t {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .index-solution .solution-item .mob-bg .t {
    padding-right: 19px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .mob-bg .t {
    padding-right: 18px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .mob-bg .t {
    padding-right: 17px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .mob-bg .t {
    padding-right: 16px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .mob-bg .t {
    padding-right: 15px;
  }
}

.index-solution .solution-item .mob-bg .t::before {
  content: '';
  display: block;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 10px;
  width: 10px;
  height: 10px;
}

@media (max-width: 1600px) {
  .index-solution .solution-item .mob-bg .t::before {
    width: 9.2px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .mob-bg .t::before {
    width: 8.4px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .mob-bg .t::before {
    width: 7.6px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .mob-bg .t::before {
    width: 6.8px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .mob-bg .t::before {
    width: 6px;
  }
}

@media (max-width: 1600px) {
  .index-solution .solution-item .mob-bg .t::before {
    height: 9.2px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .mob-bg .t::before {
    height: 8.4px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .mob-bg .t::before {
    height: 7.6px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .mob-bg .t::before {
    height: 6.8px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .mob-bg .t::before {
    height: 6px;
  }
}

.index-solution .solution-item .item-left {
  flex-shrink: 0;
  box-sizing: border-box;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  width: 55%;
  height: 100%;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 120px;
  padding-bottom: 120px;
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left {
    padding-left: 42px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left {
    padding-left: 34px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left {
    padding-left: 26px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left {
    padding-left: 18px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left {
    padding-right: 42px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left {
    padding-right: 34px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left {
    padding-right: 26px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left {
    padding-right: 18px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left {
    padding-top: 100px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left {
    padding-top: 80px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left {
    padding-top: 60px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left {
    padding-top: 40px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left {
    padding-bottom: 100px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left {
    padding-bottom: 80px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left {
    padding-bottom: 60px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left {
    padding-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left {
    padding-bottom: 20px;
  }
}

.index-solution .solution-item .item-left .text-top {
  display: block;
  margin-bottom: 55px;
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left .text-top {
    display: none;
  }
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left .text-top {
    margin-bottom: 53px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left .text-top {
    margin-bottom: 51px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left .text-top {
    margin-bottom: 49px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left .text-top {
    margin-bottom: 47px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left .text-top {
    margin-bottom: 45px;
  }
}

.index-solution .solution-item .item-left .text-top h1 {
  align-items: center;
  display: flex;
  color: #00bae7;
  font-size: 28px;
  margin-bottom: 35px;
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left .text-top h1 {
    display: none;
  }
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left .text-top h1 {
    font-size: 25.6px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left .text-top h1 {
    font-size: 23.2px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left .text-top h1 {
    font-size: 20.8px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left .text-top h1 {
    font-size: 18.4px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left .text-top h1 {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left .text-top h1 {
    margin-bottom: 31px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left .text-top h1 {
    margin-bottom: 27px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left .text-top h1 {
    margin-bottom: 23px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left .text-top h1 {
    margin-bottom: 19px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left .text-top h1 {
    margin-bottom: 15px;
  }
}

.index-solution .solution-item .item-left .text-top h1::before {
  content: '';
  display: block;
  border-radius: 50%;
  background-color: #317ec6;
  margin-right: 10px;
  width: 10px;
  height: 10px;
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left .text-top h1::before {
    width: 9.2px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left .text-top h1::before {
    width: 8.4px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left .text-top h1::before {
    width: 7.6px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left .text-top h1::before {
    width: 6.8px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left .text-top h1::before {
    width: 6px;
  }
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left .text-top h1::before {
    height: 9.2px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left .text-top h1::before {
    height: 8.4px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left .text-top h1::before {
    height: 7.6px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left .text-top h1::before {
    height: 6.8px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left .text-top h1::before {
    height: 6px;
  }
}

.index-solution .solution-item .item-left .text-top h2 {
  font-weight: bold;
  text-transform: uppercase;
  line-height: .9;
  color: #317ec6;
  font-size: 80px;
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left .text-top h2 {
    font-size: 68.4px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left .text-top h2 {
    font-size: 56.8px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left .text-top h2 {
    font-size: 45.2px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left .text-top h2 {
    font-size: 33.6px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left .text-top h2 {
    font-size: 22px;
  }
}

.index-solution .solution-item .item-left .text-bottom .item-link {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 50px;
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left .text-bottom .item-link {
    margin-bottom: 43px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left .text-bottom .item-link {
    margin-bottom: 36px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left .text-bottom .item-link {
    margin-bottom: 29px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left .text-bottom .item-link {
    margin-bottom: 22px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left .text-bottom .item-link {
    margin-bottom: 15px;
  }
}

.index-solution .solution-item .item-left .text-bottom .item-link a {
  flex-grow: 1;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  width: 50%;
  font-size: 24px;
  color: #317ec6;
  padding-bottom: 30px;
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a {
    font-size: 22.4px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a {
    font-size: 20.8px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a {
    font-size: 19.2px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a {
    font-size: 17.6px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a {
    padding-bottom: 26px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a {
    padding-bottom: 22px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a {
    padding-bottom: 18px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a {
    padding-bottom: 14px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a {
    padding-bottom: 10px;
  }
}

.index-solution .solution-item .item-left .text-bottom .item-link a:hover {
  color: #00bae7;
}

.index-solution .solution-item .item-left .text-bottom .item-link a:hover::after {
  transform: scaleX(1);
}

.index-solution .solution-item .item-left .text-bottom .item-link a::before {
  content: '';
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background-color: #00bae7;
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a::before {
    height: 2.8px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a::before {
    height: 2.6px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a::before {
    height: 2.4px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a::before {
    height: 2.2px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a::before {
    height: 2px;
  }
}

.index-solution .solution-item .item-left .text-bottom .item-link a::after {
  content: '';
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0, 1);
  height: 3px;
  background-color: #317ec6;
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a::after {
    height: 2.8px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a::after {
    height: 2.6px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a::after {
    height: 2.4px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a::after {
    height: 2.2px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a::after {
    height: 2px;
  }
}

.index-solution .solution-item .item-left .text-bottom .item-link a span {
  font-weight: normal;
  color: #7f8faf;
  font-size: 16px;
  margin-right: 15px;
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a span {
    font-size: 15.2px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a span {
    font-size: 14.4px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a span {
    font-size: 13.6px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a span {
    font-size: 12.8px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a span {
    font-size: 12px;
  }
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a span {
    margin-right: 14px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a span {
    margin-right: 13px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a span {
    margin-right: 12px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a span {
    margin-right: 11px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left .text-bottom .item-link a span {
    margin-right: 10px;
  }
}

.index-solution .solution-item .item-left .text-bottom .item-desc {
  line-height: 1.8;
  color: #585858;
  font-size: 18px;
  margin-bottom: 40px;
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left .text-bottom .item-desc {
    line-height: 1.76;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left .text-bottom .item-desc {
    line-height: 1.72;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left .text-bottom .item-desc {
    line-height: 1.68;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left .text-bottom .item-desc {
    line-height: 1.64;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left .text-bottom .item-desc {
    line-height: 1.6;
  }
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left .text-bottom .item-desc {
    font-size: 17.2px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left .text-bottom .item-desc {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left .text-bottom .item-desc {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left .text-bottom .item-desc {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left .text-bottom .item-desc {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .index-solution .solution-item .item-left .text-bottom .item-desc {
    margin-bottom: 36px;
  }
}

@media (max-width: 1366px) {
  .index-solution .solution-item .item-left .text-bottom .item-desc {
    margin-bottom: 32px;
  }
}

@media (max-width: 1024px) {
  .index-solution .solution-item .item-left .text-bottom .item-desc {
    margin-bottom: 28px;
  }
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-left .text-bottom .item-desc {
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .index-solution .solution-item .item-left .text-bottom .item-desc {
    margin-bottom: 20px;
  }
}

.index-solution .solution-item .item-right {
  flex-shrink: 0;
  background-attachment: fixed;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: auto 120%;
  width: 45%;
}

@media (max-width: 991px) {
  .index-solution .solution-item .item-right {
    width: 0;
  }
}

.index-application {
  background-color: #f2f4f8;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 1024px) {
  .index-application {
    height: 550px;
  }
}

@media (max-width: 991px) {
  .index-application {
    height: 450px;
  }
}

@media (max-width: 767px) {
  .index-application {
    height: auto;
  }
}

@media (max-width: 1600px) {
  .index-application {
    padding-top: 44px;
  }
}

@media (max-width: 1366px) {
  .index-application {
    padding-top: 38px;
  }
}

@media (max-width: 1024px) {
  .index-application {
    padding-top: 32px;
  }
}

@media (max-width: 991px) {
  .index-application {
    padding-top: 26px;
  }
}

@media (max-width: 767px) {
  .index-application {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-application {
    padding-bottom: 44px;
  }
}

@media (max-width: 1366px) {
  .index-application {
    padding-bottom: 38px;
  }
}

@media (max-width: 1024px) {
  .index-application {
    padding-bottom: 32px;
  }
}

@media (max-width: 991px) {
  .index-application {
    padding-bottom: 26px;
  }
}

@media (max-width: 767px) {
  .index-application {
    padding-bottom: 20px;
  }
}

.index-application .application-title {
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: solid #317ec6;
  border-width: 3px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 50px;
  margin-bottom: 60px;
}

@media (max-width: 1600px) {
  .index-application .application-title {
    border-width: 2.8px;
  }
}

@media (max-width: 1366px) {
  .index-application .application-title {
    border-width: 2.6px;
  }
}

@media (max-width: 1024px) {
  .index-application .application-title {
    border-width: 2.4px;
  }
}

@media (max-width: 991px) {
  .index-application .application-title {
    border-width: 2.2px;
  }
}

@media (max-width: 767px) {
  .index-application .application-title {
    border-width: 2px;
  }
}

@media (max-width: 991px) {
  .index-application .application-title {
    align-items: center;
  }
}

@media (max-width: 1600px) {
  .index-application .application-title {
    padding-bottom: 43px;
  }
}

@media (max-width: 1366px) {
  .index-application .application-title {
    padding-bottom: 36px;
  }
}

@media (max-width: 1024px) {
  .index-application .application-title {
    padding-bottom: 29px;
  }
}

@media (max-width: 991px) {
  .index-application .application-title {
    padding-bottom: 22px;
  }
}

@media (max-width: 767px) {
  .index-application .application-title {
    padding-bottom: 15px;
  }
}

@media (max-width: 1600px) {
  .index-application .application-title {
    margin-bottom: 52px;
  }
}

@media (max-width: 1366px) {
  .index-application .application-title {
    margin-bottom: 44px;
  }
}

@media (max-width: 1024px) {
  .index-application .application-title {
    margin-bottom: 36px;
  }
}

@media (max-width: 991px) {
  .index-application .application-title {
    margin-bottom: 28px;
  }
}

@media (max-width: 767px) {
  .index-application .application-title {
    margin-bottom: 20px;
  }
}

.index-application .application-title .left-title .t1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #317ec6;
  font-size: 28px;
}

@media (max-width: 1600px) {
  .index-application .application-title .left-title .t1 {
    font-size: 25.6px;
  }
}

@media (max-width: 1366px) {
  .index-application .application-title .left-title .t1 {
    font-size: 23.2px;
  }
}

@media (max-width: 1024px) {
  .index-application .application-title .left-title .t1 {
    font-size: 20.8px;
  }
}

@media (max-width: 991px) {
  .index-application .application-title .left-title .t1 {
    font-size: 18.4px;
  }
}

@media (max-width: 767px) {
  .index-application .application-title .left-title .t1 {
    font-size: 16px;
  }
}

.index-application .application-title .left-title .t1::before {
  content: '';
  display: block;
  border-radius: 50%;
  background-color: #317ec6;
  background-color: #317ec6;
  margin-right: 10px;
  width: 10px;
  height: 10px;
}

@media (max-width: 1600px) {
  .index-application .application-title .left-title .t1::before {
    width: 9.2px;
  }
}

@media (max-width: 1366px) {
  .index-application .application-title .left-title .t1::before {
    width: 8.4px;
  }
}

@media (max-width: 1024px) {
  .index-application .application-title .left-title .t1::before {
    width: 7.6px;
  }
}

@media (max-width: 991px) {
  .index-application .application-title .left-title .t1::before {
    width: 6.8px;
  }
}

@media (max-width: 767px) {
  .index-application .application-title .left-title .t1::before {
    width: 6px;
  }
}

@media (max-width: 1600px) {
  .index-application .application-title .left-title .t1::before {
    height: 9.2px;
  }
}

@media (max-width: 1366px) {
  .index-application .application-title .left-title .t1::before {
    height: 8.4px;
  }
}

@media (max-width: 1024px) {
  .index-application .application-title .left-title .t1::before {
    height: 7.6px;
  }
}

@media (max-width: 991px) {
  .index-application .application-title .left-title .t1::before {
    height: 6.8px;
  }
}

@media (max-width: 767px) {
  .index-application .application-title .left-title .t1::before {
    height: 6px;
  }
}

.index-application .application-title .left-title .t2 {
  color: #317ec6;
  font-weight: bold;
  text-transform: uppercase;
  line-height: .8;
  margin-top: 40px;
  display: block;
  font-size: 100px;
}

@media (max-width: 1600px) {
  .index-application .application-title .left-title .t2 {
    margin-top: 35px;
  }
}

@media (max-width: 1366px) {
  .index-application .application-title .left-title .t2 {
    margin-top: 30px;
  }
}

@media (max-width: 1024px) {
  .index-application .application-title .left-title .t2 {
    margin-top: 25px;
  }
}

@media (max-width: 991px) {
  .index-application .application-title .left-title .t2 {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .index-application .application-title .left-title .t2 {
    margin-top: 15px;
  }
}

@media (max-width: 991px) {
  .index-application .application-title .left-title .t2 {
    display: none;
  }
}

@media (max-width: 1600px) {
  .index-application .application-title .left-title .t2 {
    font-size: 84.4px;
  }
}

@media (max-width: 1366px) {
  .index-application .application-title .left-title .t2 {
    font-size: 68.8px;
  }
}

@media (max-width: 1024px) {
  .index-application .application-title .left-title .t2 {
    font-size: 53.2px;
  }
}

@media (max-width: 991px) {
  .index-application .application-title .left-title .t2 {
    font-size: 37.6px;
  }
}

@media (max-width: 767px) {
  .index-application .application-title .left-title .t2 {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .index-application .application-title .right-more .common-more a {
    width: auto;
  }
}

@media (max-width: 767px) {
  .index-application .application-title .right-more .common-more a {
    padding-top: 3px;
  }
}

@media (max-width: 767px) {
  .index-application .application-title .right-more .common-more a {
    padding-bottom: 3px;
  }
}

@media (max-width: 767px) {
  .index-application .application-title .right-more .common-more a {
    padding-left: 14px;
  }
}

@media (max-width: 767px) {
  .index-application .application-title .right-more .common-more a {
    padding-right: 14px;
  }
}

@media (max-width: 767px) {
  .index-application .application-title .right-more .common-more a span {
    display: none;
  }
}

.index-application .cont ul {
  align-items: stretch;
  justify-content: space-between;
  display: flex;
  margin-left: -25px;
  margin-right: -25px;
}

@media (max-width: 767px) {
  .index-application .cont ul {
    display: block;
  }
}

@media (max-width: 1024px) {
  .index-application .cont ul {
    margin-left: -15px;
  }
}

@media (max-width: 991px) {
  .index-application .cont ul {
    margin-left: -10px;
  }
}

@media (max-width: 767px) {
  .index-application .cont ul {
    margin-left: 0;
  }
}

@media (max-width: 1024px) {
  .index-application .cont ul {
    margin-right: -15px;
  }
}

@media (max-width: 991px) {
  .index-application .cont ul {
    margin-right: -10px;
  }
}

@media (max-width: 767px) {
  .index-application .cont ul {
    margin-right: 0;
  }
}

.index-application .cont ul li {
  flex-shrink: 0;
  box-sizing: border-box;
  width: 33.33%;
  padding-left: 25px;
  padding-right: 25px;
}

@media (max-width: 767px) {
  .index-application .cont ul li {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .index-application .cont ul li {
    padding-left: 15px;
  }
}

@media (max-width: 991px) {
  .index-application .cont ul li {
    padding-left: 10px;
  }
}

@media (max-width: 767px) {
  .index-application .cont ul li {
    padding-left: 0;
  }
}

@media (max-width: 1024px) {
  .index-application .cont ul li {
    padding-right: 15px;
  }
}

@media (max-width: 991px) {
  .index-application .cont ul li {
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .index-application .cont ul li {
    padding-right: 0;
  }
}

.index-application .cont ul li:not(:last-child) {
  margin-bottom: 0;
  border-right: 1px solid #c2cada;
}

@media (max-width: 767px) {
  .index-application .cont ul li:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .index-application .cont ul li:not(:last-child) {
    border-right: none;
  }
}

.index-application .cont ul li a {
  display: flex;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.index-application .cont ul li .item-text {
  margin-bottom: 30px;
}

@media (max-width: 1600px) {
  .index-application .cont ul li .item-text {
    margin-bottom: 27px;
  }
}

@media (max-width: 1366px) {
  .index-application .cont ul li .item-text {
    margin-bottom: 24px;
  }
}

@media (max-width: 1024px) {
  .index-application .cont ul li .item-text {
    margin-bottom: 21px;
  }
}

@media (max-width: 991px) {
  .index-application .cont ul li .item-text {
    margin-bottom: 18px;
  }
}

@media (max-width: 767px) {
  .index-application .cont ul li .item-text {
    margin-bottom: 15px;
  }
}

.index-application .cont ul li .item-text .item-title {
  font-weight: bold;
  color: #317ec6;
  font-size: 24px;
  margin-bottom: 15px;
}

@media (max-width: 1600px) {
  .index-application .cont ul li .item-text .item-title {
    font-size: 22.4px;
  }
}

@media (max-width: 1366px) {
  .index-application .cont ul li .item-text .item-title {
    font-size: 20.8px;
  }
}

@media (max-width: 1024px) {
  .index-application .cont ul li .item-text .item-title {
    font-size: 19.2px;
  }
}

@media (max-width: 991px) {
  .index-application .cont ul li .item-text .item-title {
    font-size: 17.6px;
  }
}

@media (max-width: 767px) {
  .index-application .cont ul li .item-text .item-title {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-application .cont ul li .item-text .item-title {
    margin-bottom: 13.6px;
  }
}

@media (max-width: 1366px) {
  .index-application .cont ul li .item-text .item-title {
    margin-bottom: 12.2px;
  }
}

@media (max-width: 1024px) {
  .index-application .cont ul li .item-text .item-title {
    margin-bottom: 10.8px;
  }
}

@media (max-width: 991px) {
  .index-application .cont ul li .item-text .item-title {
    margin-bottom: 9.4px;
  }
}

@media (max-width: 767px) {
  .index-application .cont ul li .item-text .item-title {
    margin-bottom: 8px;
  }
}

.index-application .cont ul li .item-text .item-desc {
  font-size: 18px;
}

@media (max-width: 1600px) {
  .index-application .cont ul li .item-text .item-desc {
    font-size: 17.2px;
  }
}

@media (max-width: 1366px) {
  .index-application .cont ul li .item-text .item-desc {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .index-application .cont ul li .item-text .item-desc {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .index-application .cont ul li .item-text .item-desc {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .index-application .cont ul li .item-text .item-desc {
    font-size: 14px;
  }
}

.index-application .cont ul li .item-img {
  position: relative;
  overflow: hidden;
}

.index-application .cont ul li .item-img:hover img {
  transform: scale(1.1);
  filter: contrast(130%);
}

.index-application .cont ul li .item-img:hover .img-t {
  transform: translateX(-100%);
}

@media (max-width: 1024px) {
  .index-application .cont ul li .item-img:hover .img-t {
    transform: translateX(0);
  }
}

.index-application .cont ul li .item-img img {
  display: block;
  width: 100%;
  height: auto;
  transition: all .5s;
}

.index-application .cont ul li .item-img .img-t {
  position: absolute;
  background-color: rgba(0, 186, 231, 0.89);
  color: #fff;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.3s cubic-bezier(0.4, 0, 0, 1);
  bottom: 0;
  left: 100%;
  right: auto;
  display: flex;
  padding-bottom: 30px;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
  width: 19%;
  height: 100%;
  writing-mode: vertical-lr;
}

@media (max-width: 1024px) {
  .index-application .cont ul li .item-img .img-t {
    left: auto;
  }
}

@media (max-width: 1024px) {
  .index-application .cont ul li .item-img .img-t {
    right: 0;
  }
}

@media (max-width: 1600px) {
  .index-application .cont ul li .item-img .img-t {
    padding-bottom: 27px;
  }
}

@media (max-width: 1366px) {
  .index-application .cont ul li .item-img .img-t {
    padding-bottom: 24px;
  }
}

@media (max-width: 1024px) {
  .index-application .cont ul li .item-img .img-t {
    padding-bottom: 21px;
  }
}

@media (max-width: 991px) {
  .index-application .cont ul li .item-img .img-t {
    padding-bottom: 18px;
  }
}

@media (max-width: 767px) {
  .index-application .cont ul li .item-img .img-t {
    padding-bottom: 15px;
  }
}

@media (max-width: 1600px) {
  .index-application .cont ul li .item-img .img-t {
    padding-top: 27px;
  }
}

@media (max-width: 1366px) {
  .index-application .cont ul li .item-img .img-t {
    padding-top: 24px;
  }
}

@media (max-width: 1024px) {
  .index-application .cont ul li .item-img .img-t {
    padding-top: 21px;
  }
}

@media (max-width: 991px) {
  .index-application .cont ul li .item-img .img-t {
    padding-top: 18px;
  }
}

@media (max-width: 767px) {
  .index-application .cont ul li .item-img .img-t {
    padding-top: 15px;
  }
}

@media (max-width: 1600px) {
  .index-application .cont ul li .item-img .img-t {
    padding-left: 10px;
  }
}

@media (max-width: 1366px) {
  .index-application .cont ul li .item-img .img-t {
    padding-left: 10px;
  }
}

@media (max-width: 1024px) {
  .index-application .cont ul li .item-img .img-t {
    padding-left: 10px;
  }
}

@media (max-width: 991px) {
  .index-application .cont ul li .item-img .img-t {
    padding-left: 10px;
  }
}

@media (max-width: 767px) {
  .index-application .cont ul li .item-img .img-t {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-application .cont ul li .item-img .img-t {
    padding-right: 10px;
  }
}

@media (max-width: 1366px) {
  .index-application .cont ul li .item-img .img-t {
    padding-right: 10px;
  }
}

@media (max-width: 1024px) {
  .index-application .cont ul li .item-img .img-t {
    padding-right: 10px;
  }
}

@media (max-width: 991px) {
  .index-application .cont ul li .item-img .img-t {
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .index-application .cont ul li .item-img .img-t {
    padding-right: 10px;
  }
}

.index-application .cont ul li .item-img .img-t span {
  margin-bottom: 10px;
}

.index-news {
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 1024px) {
  .index-news {
    height: 600px;
  }
}

@media (max-width: 991px) {
  .index-news {
    height: 500px;
  }
}

@media (max-width: 767px) {
  .index-news {
    height: auto;
  }
}

@media (max-width: 1600px) {
  .index-news {
    padding-top: 44px;
  }
}

@media (max-width: 1366px) {
  .index-news {
    padding-top: 38px;
  }
}

@media (max-width: 1024px) {
  .index-news {
    padding-top: 32px;
  }
}

@media (max-width: 991px) {
  .index-news {
    padding-top: 26px;
  }
}

@media (max-width: 767px) {
  .index-news {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-news {
    padding-bottom: 44px;
  }
}

@media (max-width: 1366px) {
  .index-news {
    padding-bottom: 38px;
  }
}

@media (max-width: 1024px) {
  .index-news {
    padding-bottom: 32px;
  }
}

@media (max-width: 991px) {
  .index-news {
    padding-bottom: 26px;
  }
}

@media (max-width: 767px) {
  .index-news {
    padding-bottom: 20px;
  }
}

.index-news .news-title {
  width: 100%;
  justify-content: space-between;
  border-bottom: solid #00bae7;
  position: relative;
  border-width: 3px;
  display: flex;
  align-items: center;
  padding-bottom: 50px;
  margin-bottom: 20px;
}

@media (max-width: 1600px) {
  .index-news .news-title {
    border-width: 2.8px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-title {
    border-width: 2.6px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-title {
    border-width: 2.4px;
  }
}

@media (max-width: 991px) {
  .index-news .news-title {
    border-width: 2.2px;
  }
}

@media (max-width: 767px) {
  .index-news .news-title {
    border-width: 2px;
  }
}

@media (max-width: 1600px) {
  .index-news .news-title {
    padding-bottom: 43px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-title {
    padding-bottom: 36px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-title {
    padding-bottom: 29px;
  }
}

@media (max-width: 991px) {
  .index-news .news-title {
    padding-bottom: 22px;
  }
}

@media (max-width: 767px) {
  .index-news .news-title {
    padding-bottom: 15px;
  }
}

@media (max-width: 1600px) {
  .index-news .news-title {
    margin-bottom: 16px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-title {
    margin-bottom: 12px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-title {
    margin-bottom: 8px;
  }
}

@media (max-width: 991px) {
  .index-news .news-title {
    margin-bottom: 4px;
  }
}

@media (max-width: 767px) {
  .index-news .news-title {
    margin-bottom: 0px;
  }
}

.index-news .news-title .left-title .t1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #00bae7;
  font-size: 28px;
}

@media (max-width: 1600px) {
  .index-news .news-title .left-title .t1 {
    font-size: 25.6px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-title .left-title .t1 {
    font-size: 23.2px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-title .left-title .t1 {
    font-size: 20.8px;
  }
}

@media (max-width: 991px) {
  .index-news .news-title .left-title .t1 {
    font-size: 18.4px;
  }
}

@media (max-width: 767px) {
  .index-news .news-title .left-title .t1 {
    font-size: 16px;
  }
}

.index-news .news-title .left-title .t1::before {
  content: '';
  display: block;
  border-radius: 50%;
  background-color: #317ec6;
  background-color: #317ec6;
  margin-right: 10px;
  width: 10px;
  height: 10px;
}

@media (max-width: 1600px) {
  .index-news .news-title .left-title .t1::before {
    width: 9.2px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-title .left-title .t1::before {
    width: 8.4px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-title .left-title .t1::before {
    width: 7.6px;
  }
}

@media (max-width: 991px) {
  .index-news .news-title .left-title .t1::before {
    width: 6.8px;
  }
}

@media (max-width: 767px) {
  .index-news .news-title .left-title .t1::before {
    width: 6px;
  }
}

@media (max-width: 1600px) {
  .index-news .news-title .left-title .t1::before {
    height: 9.2px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-title .left-title .t1::before {
    height: 8.4px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-title .left-title .t1::before {
    height: 7.6px;
  }
}

@media (max-width: 991px) {
  .index-news .news-title .left-title .t1::before {
    height: 6.8px;
  }
}

@media (max-width: 767px) {
  .index-news .news-title .left-title .t1::before {
    height: 6px;
  }
}

.index-news .news-title .left-title .t2 {
  color: #317ec6;
  font-weight: bold;
  text-transform: uppercase;
  line-height: .8;
  display: block;
  margin-top: 40px;
  font-size: 80px;
}

@media (max-width: 991px) {
  .index-news .news-title .left-title .t2 {
    display: none;
  }
}

@media (max-width: 1600px) {
  .index-news .news-title .left-title .t2 {
    margin-top: 35px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-title .left-title .t2 {
    margin-top: 30px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-title .left-title .t2 {
    margin-top: 25px;
  }
}

@media (max-width: 991px) {
  .index-news .news-title .left-title .t2 {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .index-news .news-title .left-title .t2 {
    margin-top: 15px;
  }
}

@media (max-width: 1600px) {
  .index-news .news-title .left-title .t2 {
    font-size: 68.4px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-title .left-title .t2 {
    font-size: 56.8px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-title .left-title .t2 {
    font-size: 45.2px;
  }
}

@media (max-width: 991px) {
  .index-news .news-title .left-title .t2 {
    font-size: 33.6px;
  }
}

@media (max-width: 767px) {
  .index-news .news-title .left-title .t2 {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .index-news .news-title .right-more .common-more a {
    width: auto;
  }
}

@media (max-width: 767px) {
  .index-news .news-title .right-more .common-more a {
    padding-top: 3px;
  }
}

@media (max-width: 767px) {
  .index-news .news-title .right-more .common-more a {
    padding-bottom: 3px;
  }
}

@media (max-width: 767px) {
  .index-news .news-title .right-more .common-more a {
    padding-left: 14px;
  }
}

@media (max-width: 767px) {
  .index-news .news-title .right-more .common-more a {
    padding-right: 14px;
  }
}

@media (max-width: 767px) {
  .index-news .news-title .right-more .common-more a span {
    display: none;
  }
}

.index-news .news-title .swiper-controller {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 0;
  right: 0;
  height: 70px;
}

@media (max-width: 991px) {
  .index-news .news-title .swiper-controller {
    bottom: 30px;
  }
}

@media (max-width: 767px) {
  .index-news .news-title .swiper-controller {
    bottom: 17px;
  }
}

@media (max-width: 991px) {
  .index-news .news-title .swiper-controller {
    right: 200px;
  }
}

@media (max-width: 767px) {
  .index-news .news-title .swiper-controller {
    right: 70px;
  }
}

@media (max-width: 1600px) {
  .index-news .news-title .swiper-controller {
    height: 62px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-title .swiper-controller {
    height: 54px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-title .swiper-controller {
    height: 46px;
  }
}

@media (max-width: 991px) {
  .index-news .news-title .swiper-controller {
    height: 38px;
  }
}

@media (max-width: 767px) {
  .index-news .news-title .swiper-controller {
    height: 30px;
  }
}

.index-news .news-title .swiper-controller .swiper-button-prev, .index-news .news-title .swiper-controller .swiper-button-next {
  position: static;
  margin: 0;
}

.index-news .news-title .swiper-controller .swiper-button-prev::after, .index-news .news-title .swiper-controller .swiper-button-next::after {
  color: #317ec6;
  font-family: 'iconfont';
  font-size: 40px;
}

@media (max-width: 1600px) {
  .index-news .news-title .swiper-controller .swiper-button-prev::after, .index-news .news-title .swiper-controller .swiper-button-next::after {
    font-size: 38px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-title .swiper-controller .swiper-button-prev::after, .index-news .news-title .swiper-controller .swiper-button-next::after {
    font-size: 36px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-title .swiper-controller .swiper-button-prev::after, .index-news .news-title .swiper-controller .swiper-button-next::after {
    font-size: 34px;
  }
}

@media (max-width: 991px) {
  .index-news .news-title .swiper-controller .swiper-button-prev::after, .index-news .news-title .swiper-controller .swiper-button-next::after {
    font-size: 32px;
  }
}

@media (max-width: 767px) {
  .index-news .news-title .swiper-controller .swiper-button-prev::after, .index-news .news-title .swiper-controller .swiper-button-next::after {
    font-size: 30px;
  }
}

.index-news .news-title .swiper-controller .swiper-button-prev {
  padding-right: 15px;
}

@media (max-width: 1600px) {
  .index-news .news-title .swiper-controller .swiper-button-prev {
    padding-right: 12px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-title .swiper-controller .swiper-button-prev {
    padding-right: 9px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-title .swiper-controller .swiper-button-prev {
    padding-right: 6px;
  }
}

@media (max-width: 991px) {
  .index-news .news-title .swiper-controller .swiper-button-prev {
    padding-right: 3px;
  }
}

@media (max-width: 767px) {
  .index-news .news-title .swiper-controller .swiper-button-prev {
    padding-right: 0px;
  }
}

.index-news .news-title .swiper-controller .swiper-button-prev::after {
  content: '\eb05';
}

.index-news .news-title .swiper-controller .swiper-button-next {
  padding-left: 15px;
}

@media (max-width: 1600px) {
  .index-news .news-title .swiper-controller .swiper-button-next {
    padding-left: 12px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-title .swiper-controller .swiper-button-next {
    padding-left: 9px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-title .swiper-controller .swiper-button-next {
    padding-left: 6px;
  }
}

@media (max-width: 991px) {
  .index-news .news-title .swiper-controller .swiper-button-next {
    padding-left: 3px;
  }
}

@media (max-width: 767px) {
  .index-news .news-title .swiper-controller .swiper-button-next {
    padding-left: 0px;
  }
}

.index-news .news-title .swiper-controller .swiper-button-next::after {
  content: '\eb03';
}

.index-news .news-swiper {
  width: 100%;
}

.index-news .news-swiper .swiper {
  padding-top: 40px;
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 1600px) {
  .index-news .news-swiper .swiper {
    padding-top: 36px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper {
    padding-top: 32px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper {
    padding-top: 28px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper {
    padding-top: 24px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-news .news-swiper .swiper {
    padding-left: 42px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper {
    padding-left: 34px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper {
    padding-left: 26px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper {
    padding-left: 18px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-news .news-swiper .swiper {
    padding-right: 42px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper {
    padding-right: 34px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper {
    padding-right: 26px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper {
    padding-right: 18px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper {
    padding-right: 10px;
  }
}

.index-news .news-swiper .swiper .swiper-slide:first-child a {
  border-left: 1px solid #d0d6e2;
}

.index-news .news-swiper .swiper .swiper-slide a {
  display: block;
  position: relative;
  border-top: 1px solid #d0d6e2;
  border-bottom: 1px solid #d0d6e2;
  border-right: 1px solid #d0d6e2;
  transition: all .3s;
  overflow: hidden;
  transform-origin: bottom;
  padding-top: 90px;
  padding-bottom: 90px;
  padding-left: 45px;
  padding-right: 45px;
}

@media (max-width: 1600px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-top: 78px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-top: 66px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-top: 54px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-top: 42px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-top: 30px;
  }
}

@media (max-width: 1600px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-bottom: 78px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-bottom: 66px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-bottom: 54px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-bottom: 42px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-bottom: 30px;
  }
}

@media (max-width: 1600px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-left: 39px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-left: 33px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-left: 27px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-left: 21px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-right: 39px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-right: 33px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-right: 27px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-right: 21px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper .swiper-slide a {
    padding-right: 15px;
  }
}

.index-news .news-swiper .swiper .swiper-slide a:hover {
  transform: scaleY(1.04);
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: #317ec6;
}

.index-news .news-swiper .swiper .swiper-slide a:hover .item-img {
  opacity: .5;
  transform: scale(1.05);
}

.index-news .news-swiper .swiper .swiper-slide a:hover .item-title, .index-news .news-swiper .swiper .swiper-slide a:hover .item-desc, .index-news .news-swiper .swiper .swiper-slide a:hover .item-date {
  color: #fff;
}

.index-news .news-swiper .swiper .swiper-slide a:hover .item-desc {
  opacity: 1;
}

.index-news .news-swiper .swiper .swiper-slide a .item-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all .3s;
  opacity: 0;
}

.index-news .news-swiper .swiper .swiper-slide a .item-title, .index-news .news-swiper .swiper .swiper-slide a .item-desc, .index-news .news-swiper .swiper .swiper-slide a .item-date {
  position: relative;
  z-index: 1;
  transition: all .25s;
}

.index-news .news-swiper .swiper .swiper-slide a .item-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 76px;
  line-height: 1.6;
  font-size: 24px;
  margin-bottom: 60px;
}

@media (max-width: 1600px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-title {
    height: 70.8px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-title {
    height: 65.6px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-title {
    height: 60.4px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-title {
    height: 55.2px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-title {
    height: 50px;
  }
}

@media (max-width: 1600px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-title {
    font-size: 22.4px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-title {
    font-size: 20.8px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-title {
    font-size: 19.2px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-title {
    font-size: 17.6px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-title {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-title {
    margin-bottom: 52px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-title {
    margin-bottom: 44px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-title {
    margin-bottom: 36px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-title {
    margin-bottom: 28px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-title {
    margin-bottom: 20px;
  }
}

.index-news .news-swiper .swiper .swiper-slide a .item-desc {
  color: #fff;
  opacity: 0;
  height: 90px;
  line-height: 1.875;
  margin-bottom: 120px;
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-desc {
    color: #585858;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-desc {
    opacity: 1;
  }
}

@media (max-width: 1600px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-desc {
    height: 84.6px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-desc {
    height: 79.2px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-desc {
    height: 73.8px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-desc {
    height: 68.4px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-desc {
    height: 63px;
  }
}

@media (max-width: 1600px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-desc {
    line-height: 1.82;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-desc {
    line-height: 1.765;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-desc {
    line-height: 1.71;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-desc {
    line-height: 1.655;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-desc {
    line-height: 1.6;
  }
}

@media (max-width: 1600px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-desc {
    margin-bottom: 102px;
  }
}

@media (max-width: 1366px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-desc {
    margin-bottom: 84px;
  }
}

@media (max-width: 1024px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-desc {
    margin-bottom: 66px;
  }
}

@media (max-width: 991px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-desc {
    margin-bottom: 48px;
  }
}

@media (max-width: 767px) {
  .index-news .news-swiper .swiper .swiper-slide a .item-desc {
    margin-bottom: 30px;
  }
}

.index-news .news-swiper .swiper .swiper-slide a .item-desc .t-cont {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.index-news .news-swiper .swiper .swiper-slide a .item-date {
  color: #7082a6;
}

@keyframes circle {
  0% {
    stroke-dashoffset: 120px;
  }
  50% {
    stroke-dashoffset: 60px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}

@keyframes circle2 {
  0% {
    stroke-dashoffset: 82px;
  }
  50% {
    stroke-dashoffset: 41px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}

@keyframes scrollrain {
  0% {
    transform: scaleY(0);
    transform-origin: top;
  }
  50% {
    transform: scaleY(1);
    transform-origin: top;
  }
  60% {
    transform-origin: bottom;
  }
  100% {
    transform: scaleY(0);
    transform-origin: bottom;
  }
}
